<template>
  <div class='upload-address-proof-verification-main-wrapper'>
    <a-form>
      <a-row style='display: unset'>
        <a-col>
          <div style='margin-top: 100px'>
            <!-- <div>
            <img src='../../../assets/panImage.png' />
          </div> -->
            <div>
              <div class='upload-address-proof-verification-title'>
                Let's verify your address
              </div>
              <div class='upload-address-proof-verification-stepper'>
                Hello {{ clientName }}!
              </div>

              <div
                class='upload-address-proof-verification-sub-title-description'
              >
                We will read data automatically from your uploaded aadhaar.
              </div>
              <div
                class='upload-address-proof-verification-sub-title-description'
              >
                You can also edit details after uploading aadhaar.
              </div>
              <div style='display: flex'>
                <div
                  class='uploaded-file-name-with-delete-btn'
                  style='margin-right: 10px'
                >
                  <div>{{ frontAadharName }}</div>
                  <div @click='onClickDelete' style='cursor: pointer'>
                    <delete-outlined />
                  </div>
                </div>
                <div class='uploaded-file-name-with-delete-btn'>
                  <div>{{ backAadharName }}</div>
                  <div @click='onClickDelete' style='cursor: pointer'>
                    <delete-outlined />
                  </div>
                </div>
              </div>
              <div style='display: flex; margin-top: 20px'>
                <div style='width: 100%; margin-right: 10px'>
                  <a-form-item v-bind='validateInfos.street'>
                    <a-input
                      class='input-field'
                      v-model:value='modelRef.street'
                      placeholder='Address Line 1'
                      :disabled='disable'
                    >
                    </a-input>
                  </a-form-item>
                </div>
                <div style='width: 100%'>
                  <a-form-item v-bind='validateInfos.landmark'>
                    <a-input
                      class='input-field'
                      v-model:value='modelRef.landmark'
                      placeholder='Address Line 2'
                      :disabled='disable'
                    >
                    </a-input>
                  </a-form-item>
                </div>
              </div>
              <div style='display: flex; margin-top: 20px'>
                <div style='width: 100%; margin-right: 10px'>
                  <a-form-item v-bind='validateInfos.pin'>
                    <a-input
                      class='input-field'
                      v-model:value='modelRef.pin'
                      placeholder='Pincode'
                      :disabled='disable'
                    >
                    </a-input>
                  </a-form-item>
                </div>
                <div style='width: 100%'>
                  <a-form-item v-bind='validateInfos.city'>
                    <a-input
                      class='input-field'
                      v-model:value='modelRef.city'
                      placeholder='City'
                      :disabled='disable'
                    >
                    </a-input>
                  </a-form-item>
                </div>
              </div>
              <div style='display: flex; margin-top: 20px'>
                <div style='width: 100%; margin-right: 10px'>
                  <a-form-item v-bind='validateInfos.state'>
                    <a-input
                      class='input-field'
                      v-model:value='modelRef.state'
                      placeholder='State'
                      :disabled='disable'
                    >
                    </a-input>
                  </a-form-item>
                </div>
                <div style='width: 100%'>
                  <a-form-item v-bind='validateInfos.country'>
                    <a-input
                      class='input-field'
                      v-model:value='modelRef.country'
                      placeholder='Country'
                      :disabled='disable'
                    >
                    </a-input>
                  </a-form-item>
                </div>
              </div>

              <div class='pan-details-submit-btn-wrapper'>
                <a-button type='primary' @click='onClickContinue' :loading="loading"
                  >My address details are correct</a-button
                >
              </div>
            </div>
          </div>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>
<script>
import { useRouter } from 'vue-router';
import {
  onMounted,
  reactive,
  computed,
  ref,
} from 'vue';
import { Form } from 'ant-design-vue';
import { DeleteOutlined } from '@ant-design/icons-vue';
import { useStore } from 'vuex';
import STAGES from '../../../constants/stages';
import services from '../../../services/apis';

const { useForm } = Form;

export default {
  components: {
    DeleteOutlined,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const disable = ref(false);
    const loading = ref(false);
    const clientName = computed(
      () => store.getters['onboardingStore/getClientName'],
    ).value;
    const modelRef = reactive({
      street: '',
      landmark: '',
      pin: '',
      city: '',
      state: '',
      country: 'INDIA',
    });
    const addressObj = computed(
      () => store.getters['onboardingStore/getUploadedAadhar'],
    ).value;
    if (addressObj.address_information) {
      modelRef.street = addressObj.address_information.address ? addressObj.address_information.address : '';
      modelRef.landmark = addressObj.address_information.locality_or_post_office ? addressObj.address_information.locality_or_post_office : '';
      modelRef.pin = addressObj.address_information.pincode ? addressObj.address_information.pincode : '';
      modelRef.city = addressObj.address_information.district_or_city ? addressObj.address_information.district_or_city : '';
      modelRef.state = addressObj.address_information.state ? addressObj.address_information.state : '';
    }
    const frontAadharName = computed(
      () => store.getters['onboardingStore/getFrontAadhar'],
    ).value;
    const backAadharName = computed(
      () => store.getters['onboardingStore/getBackAadhar'],
    ).value;
    onMounted(() => {
      window.scrollTo(0, 0);
      const currentStage = computed(
        () => store.getters['onboardingStore/getCurrentStage'],
      ).value;
      if (currentStage !== STAGES.STAGE_TYPE.ADDRESS_PROOF) {
        services
          .getStages()
          .then((response) => {
            store.dispatch('onboardingStore/updateStage', response.data.data.stage);
          })
          .catch(() => {
          });
      }
    });
    const rulesRef = reactive({
      street: [
        {
          required: true,
          message: 'Enter your address',
        },
      ],
      landmark: [
        {
          required: true,
          message: 'Enter your address',
        },
      ],
      pin: [
        {
          required: true,
          message: 'Enter your pincode',
        },
      ],
      city: [
        {
          required: true,
          message: 'Enter your city',
        },
      ],
      state: [
        {
          required: true,
          message: 'Enter your state',
        },
      ],
      country: [
        {
          required: true,
          message: 'Enter your country',
        },
      ],
    });
    const { resetFields, validate, validateInfos } = useForm(
      modelRef,
      rulesRef,
    );
    const onClickContinue = () => {
      const data = {
        type: 'AADHAAR',
        details: {
          street: modelRef.street,
          landmark: modelRef.landmark,
          pin: parseInt(modelRef.pin, 10),
          city: modelRef.city,
          state: modelRef.state,
          country: modelRef.country,
        },
      };
      validate().then(() => {
        loading.value = true;
        disable.value = true;
        services
          .confirmUploadedAddressProof(data)
          .then((response) => {
            loading.value = false;
            store.dispatch('onboardingStore/updateStage', response.data.data.stage);
            // router.push('/bank');
          })
          .catch(() => {
            loading.value = false;
            disable.value = false;
          });
      });
    };
    const onClickDelete = () => {
      router.push('/onboarding/upload/address');
    };
    return {
      onClickContinue,
      frontAadharName,
      backAadharName,
      onClickDelete,
      clientName,
      validateInfos,
      resetFields,
      modelRef,
      disable,
      loading,
    };
  },
};
</script>

<style lang='scss'>
@import '../styles/uploadAddressProofVerification.scss';
</style>
